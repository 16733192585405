import React from 'react';
import logo from './logomain.png';

function Header() {
  return (
    <header>
      <h1></h1>
    </header>
  );
}

function Content() {
  return (
    <div id="content">
      <img src={logo}></img>
      <h1 style={{ color: '#013399', textAlign: 'center' }}>Coming Soon ...</h1>
    </div>
  );
}

function Footer() {
  return (
    <footer>
      <p></p>
    </footer>
  );
}

function Home() {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default Home;
