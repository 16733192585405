import './home.css';
import Home from './home';

function App() {
  return (
    <>
      <Home/>
    </>

  );
}

export default App;
